import React, { useMemo } from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Heading } from '../components/Heading'
import { Hero } from '../components/Hero'
import { AboutSidebar } from '../components/AboutSidebar'

import config from '../utils/config'

export default function Index() {
  return (
    <div>
      <Helmet title={config.siteTitle} />
      <SEO />

      <div className="container">
        <div className="hero-wrapper">
          <Hero title="Hey, I'm Parth 👋" index>
            <p className="hero-description small width">
              Welcome to my digital nook.
              <br />
              <br />
              As a software engineer and an aspiring entrepreneur, I embrace the journey of lifelong learning and open sharing of insights.
              My reflections and discoveries are captured in {' '}
              <Link to="/blog">blog posts</Link> and a curated list of{' '}
              <Link to="/bookshelf">books</Link> that have influenced me.

              Outside of my professional pursuits, I find joy in {' '}
              <Link to="https://www.instagram.com/safarnama.ps" target="_blank">photography</Link> and the creativity of <Link to="https://www.youtube.com/c/ParthShandilya" target="_blank">music mixing</Link>.
              <br />
              <br />
              Thank you for visiting my corner!
            </p>
          </Hero>
          <AboutSidebar />
        </div>
      </div>

      <div className="container">


        <section className="segment large">
          <Heading title="Newsletter" />
          <p>
            Sign up to get updates when I write something new. No spam ever.
          </p>
          <p>
            <a
              href="https://parthshandilya.substack.com"
              target="_blank"
              rel="noopener noreferrer"
              className="button large highlighted"
            >
              Subscribe to the Newsletter
            </a>
          </p>
        </section>
      </div>
    </div>
  )
}

Index.Layout = Layout

export const pageQuery = graphql`
  query IndexQuery {
    latest: allMarkdownRemark(
      limit: 6
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            categories
          }
        }
      }
    }
    highlights: allMarkdownRemark(
      limit: 12
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { eq: "Highlight" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            shortTitle
            tags
            thumbnail {
              childImageSharp {
                fixed(width: 45, height: 45) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
